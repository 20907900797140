import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateCodeToValue } from './translateCodeToValue.pipe';
import { TranslateEnumToValue } from './translateEnum.pipe';


@NgModule({
  declarations: [TranslateCodeToValue,TranslateEnumToValue], // <---
  imports: [CommonModule],
  exports: [TranslateCodeToValue,TranslateEnumToValue], // <---
  providers: [TranslateCodeToValue,TranslateEnumToValue]
})
export class MainPipe { }

import { CapitalizePipe } from './pipes/dummy.pipe';
import { NumberToCurrencyPipe } from './pipes/numberToCurrency.pipe';
import { DragDropDirective } from './directives/drag-drop.directive';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FormsModule } from '@angular/forms';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';

// ag-grid
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ErrorHandlingComponent } from './components/error-handling/error-handling.component';
import { SuccessBlogComponent } from './components/success-blog/success-blog.component';



import { ReactiveFormsModule } from '@angular/forms';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { TogglePasswordDirective } from './directives/password-toggle.directive';
import { MaterialModule } from './modules/material/material.module';



@NgModule({
  declarations: [TableComponent, ErrorHandlingComponent, SuccessBlogComponent,
    UploadFileComponent, 
    LogoUploadComponent, DigitOnlyDirective, NumberToCurrencyPipe, CapitalizePipe,
    TogglePasswordDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AgGridModule.withComponents([null])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TableComponent, ErrorHandlingComponent, SuccessBlogComponent,
    UploadFileComponent, LogoUploadComponent,
    DigitOnlyDirective, NumberToCurrencyPipe, TogglePasswordDirective,MaterialModule],
  entryComponents: [],
  providers: [LogoUploadComponent, DigitOnlyDirective, NumberToCurrencyPipe,
    TogglePasswordDirective]
})
export class SharedModule { }

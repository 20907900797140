import { HttpClientService } from 'src/app/services/httpClient.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';


declare function enableTreeMenu(): any; // reading external js method

var arrayToTree = require('array-to-tree'); // flat menu to tree menu


@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.css']
})
export class LeftSideMenuComponent implements OnInit {

  menuElements;

  constructor(
    private _httpClientService: HttpClientService
  ) { }

  ngOnInit() {
    // calling external js method to prepare child menu
    enableTreeMenu();

    // switch-session calling
    this._httpClientService.post('sessions/S/SYS', '', 'apiDevBaseUrlsys').subscribe(
      // success callback
      (switchSessionRes: any) => {
        console.log('switchSessionRes', switchSessionRes);
        localStorage.setItem('refreshHash', switchSessionRes.d.SecretHash);
        localStorage.setItem('MenuElements', JSON.stringify(switchSessionRes.d.MenuElements));
        localStorage.setItem('Permissions', JSON.stringify(switchSessionRes.d.Permissions));
        
        this.flatToHierarchy(switchSessionRes.d.MenuElements);
      },
      // error callback
      switchSessionError => {
        console.log('switchSessionError', switchSessionError);
      }
    )

  }

  flatToHierarchy(flat) {

    // sorting before sending to arrayToTree
    flat.sort((a, b) => { return a.order - b.order; });
    console.log('Ordered Menu', flat);

    this.menuElements = arrayToTree(flat, {
      parentProperty: 'parent',
      customID: 'key'
    });
    console.log('MenuElements', this.menuElements);
  }
}

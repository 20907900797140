import { Component, OnInit } from '@angular/core';
import { IndependentSharedService } from '../../../services/independentShared.service'

@Component({
  selector: 'app-success-blog',
  templateUrl: './success-blog.component.html',
  styleUrls: ['./success-blog.component.css']
})
export class SuccessBlogComponent {

  successbyServer: any;

  constructor(indservice: IndependentSharedService) {
    indservice._success.asObservable().subscribe((res) => {

      console.log("called Success by sharedService")
      console.log("Success by sharedService", res['msg'])
      this.successbyServer = res['msg'];
      document.getElementById('successopenmodel').click();
    });
  }

}

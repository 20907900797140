import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TranslateCodeToValue'
})

export class TranslateCodeToValue implements PipeTransform {
  transform(type: string, value: string): string {

    // console.log(' codeeeeeee to value  pipe called...../////');
    let codesAndValues = JSON.parse(localStorage.getItem('codesAndValues'));
    console.log('codesAndValues', codesAndValues[type]);
    for (let item of codesAndValues[type]) {
      // console.log('item code,', item);
      if (item['key'] == value) {
        // console.log('field code matched by pipe');
        return item['value'];
      }
    }
    return value;
  }
}

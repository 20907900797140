import { timer } from 'rxjs';
import { LoadingService } from './../services/loading.service';
import { CognitoAuth } from './../model/auth';
import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { AuthService } from '../services/auth.service';

// to decode Jwt
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isAuthProgress: boolean = true;
  public currentYear = (new Date()).getFullYear();//Display current year
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _jwtHelperService: JwtHelperService
  ) { }

  ngOnInit() {
    let code = localStorage.getItem('authorisationcode') || '';
    console.log('local storage --code', code);
    if (code == '') {
      this.isAuthProgress = false;
    } else {
      this.isAuthProgress = true;
      console.log("isAuthProgress", this.isAuthProgress);
      localStorage.setItem('isAuthProgress', this.isAuthProgress.toString());
      console.log('code', code);
      this.verifyAutnticationCode(code);
    }
  }

  // verifying authentication code
  verifyAutnticationCode(authCode) {
    try {
      const auth_code = authCode;
      let base64Input = btoa(
        environment.cognitoClientId + ':' + environment.cognitoClientSecret
      );
      let authObject = new CognitoAuth();
      authObject.grant_type = 'authorization_code';
      authObject.client_id = environment.cognitoClientId;
      authObject.redirect_uri = window.location.protocol + '//' + location.host + '/';
      authObject.code = authCode;
      this._authService.login(authObject, base64Input).subscribe(
        (response: any) => {
          console.log('response.json', response);
          console.log('response.json().access_token', response.access_token);
          const decodedToken = this._jwtHelperService.decodeToken(response.access_token);
          console.log('decodedToken', decodedToken);
          console.log('response.json().refresh_token', response.refresh_token);
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('refreshtoken', response.refresh_token);
          localStorage.setItem('logged_in_user', JSON.stringify(decodedToken));
          // starting timer for token expiry
          // timer(5000, 20000).subscribe(val => this._authService.checkTokenExpiry());
          localStorage.removeItem('isAuthProgress');
          this._router.navigate(['/vpd']).then(res => {
            this.isAuthProgress = false;  // need to think about it.
          });


        },
        error => {
          console.log('error login', error);
          this.isAuthProgress = false;
          localStorage.removeItem('isAuthProgress');
          this._router.navigate(['/login']);
          if (error && (error.status == 401 || error.status == 400 || error.status == 403)
          ) {
            // Handling Unauthorized access
            console.log('error', error);
          }
        }
      );
    } catch (exception) {
      localStorage.clear();
      console.log('catch exception', exception);
      this._router.navigate(['/login']);
    }
  }

  authenticate() {
    localStorage.removeItem('access_token');
    localStorage.clear();
    let redirectURL = environment.awsCognitoUrl + environment.cognitoClientId + '&redirect_uri=' + window.location.protocol + '//' + location.host + '/';
    window.location.href = redirectURL;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  fileUploadClear = new Subject();
  clearLogoData = new Subject();
  constructor() {
  }
  clearSelectedfile() {
    this.fileUploadClear.next();
  }
  clearLogo() {
    this.clearLogoData.next();
  }
}

import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { environment } from './../environments/environment';
import { RefreshAuth } from './model/auth';
import { timer } from 'rxjs';
import { NumberToCurrencyPipe } from './shared/pipes/numberToCurrency.pipe';
import Utils from './shared/utility.functions';
import { LoadingService } from './services/loading.service';
import { IndependentSharedService } from 'src/app/services/independentShared.service';
import { element } from 'protractor';
import { LoginComponent } from './login/login.component';
import { ToasterService } from './services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NumberToCurrencyPipe]
})
export class AppComponent implements OnInit {

  loader;
  timeStampDiff;

  constructor(
    private independentSharedService: IndependentSharedService,
    private loadingService: LoadingService,
    private _toasterService: ToasterService,
    private _numberToCurrencyPipe: NumberToCurrencyPipe,
    private _router: Router,
    private _authService: AuthService) {
    this.loader = this.loadingService;
  }

  ngOnInit() {
    // this.independentSharedService.handleError("error form modal")
    // this.loadingService.loaderStart();
    // this._toasterService.error('Error toaster app')
    // console.log('NumberToCurrencyPipe', this._numberToCurrencyPipe.transform('250000'));
    // console.log('Utils.getCurrentFinancialYear();', Utils.ageFromDateOfBirth('2020/03/18'));
    console.log('Utils.convertYYYYMMDDToDDMMYY();', Utils.convertYYYYMMDDToDDMMYYYY('2020-03-18'));

    // to get the access token  before session timeout (token refesh)
    timer(5000, 420000).subscribe(val => this._authService.checkTokenExpiry());

  }




}


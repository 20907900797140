import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class IndependentSharedService {
  public _error = new Subject<object>();
  public _success = new Subject<object>();
  // public eventerror = this._error.asObservable();

  constructor() { }

  public handleError(data: any) {
    console.log("llllllllllllllllllllll", data)
    this._error.next(data);
  }

  public handleSuccess(data: any) {
    console.log("Success", data)
    this._success.next(data);
  }

}

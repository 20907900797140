import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class JobService {
    messageRowData =[];
    totalmessagetasks= [];
    totalMessageTasksSubject= new Subject();

    messagearray =[];
    tasksarray=[];

    getMessageCenterList() {
        try {
          this._loader.loaderStart();
          console.log('get list');
      // messages?read=true&preserve=true&start=2020-05-06&end=2020-06-11
          this._httpClientService
          .get('messages'  , 'apiDevBaseUrlMsgCenter')
      
            .subscribe(
              (messageRes: any) => {
                this.messageRowData = messageRes.d;
                this._loader.loaderEnd();
                console.log('get  meassgae list', messageRes);
              },
              (err: any) => {
           
      
               // this.messageRowData = null;
                this._loader.loaderEnd();
               // this._toasterService.error(err.error.m);
                console.log('################Job Serviceget list message ', this.messageRowData);
              }
            );
        } catch (err) {
          this._loader.loaderEnd();
          this._toasterService.error(err);
        }
        }

        getMessagesTasks(): Observable<any[]> {
          return forkJoin(
            this._httpClientService.get('messages/notifications'  , 'apiDevBaseUrl'),
            this._httpClientService.get('tasks', 'apiDevBaseUrlorg'),
        
      
          );
        }


        getAllSubscribeEvents(){
          this.getMessagesTasks().subscribe(res => {
            console.log("############Response of Jobs",res);
            if(res[0].d){
              this.messagearray = res[0].d;
            }
            
           
            if(res[1].d){
              this.tasksarray = res[1].d;
            }
            
            console.log("this.tasksarray",this.tasksarray);
            console.log("this.messagearray",this.messagearray);

            this.totalmessagetasks = this.messagearray.concat(this.tasksarray);  
            console.log("this.totalmessagetasks",this.totalmessagetasks);
            this.totalMessageTasksSubject.next(this.totalmessagetasks);   
            console.log("this.totalmessagetasks",this.totalMessageTasksSubject); 
          }, err => {
            console.log('fork join error', err);
          });

        }

      
        // this.getMessagesTasks().subscribe(res => {
        //   console.log('fork join responxe', res);
        //   const codesValues = ['countries', 'states', 'cities', 'fieldvaluespair', 'fieldcodespair'];
        //   let mergedRes = {};
        //   let filteredRes = res.map((res: any, i) => {
        //     console.log('index fork ', i);
        //     return mergedRes[codesValues[i]] = res.d;
        //   })
        //   // let mergedRes = filteredRes[0].concat(filteredRes[1], filteredRes[2], filteredRes[3], filteredRes[4], filteredRes[5]);
        //   console.log('merged', mergedRes);
        //   localStorage.setItem('codesAndValues', JSON.stringify(mergedRes));
        // }, err => {
        //   console.log('fork join error', err);
        // });
  

  constructor(private _httpClientService:HttpClientService,
    private _loader:LoadingService,private _toasterService:ToasterService) {
      this.getMessagesTasks().subscribe(res => {
        console.log("############Response of Jobs",res);
        // console.log('fork join responxe', res);
        // const codesValues = ['countries', 'states', 'cities', 'fieldvaluespair', 'fieldcodespair'];
        // let mergedRes = {};
        // let filteredRes = res.map((res: any, i) => {
        //   console.log('index fork ', i);
        //   return mergedRes[codesValues[i]] = res.d;
        // })
        // // let mergedRes = filteredRes[0].concat(filteredRes[1], filteredRes[2], filteredRes[3], filteredRes[4], filteredRes[5]);
        // console.log('merged', mergedRes);
        // localStorage.setItem('codesAndValues', JSON.stringify(mergedRes));
      }, err => {
        console.log('fork join error', err);
      });
     }
}

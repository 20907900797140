import { AuthService } from './../../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { JobService } from './../../../../services/jobs-service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  loggedInUser;
  accessToken;
  refreshToken;
  tokenConfirmation;
  userNotConfired;
  timerStart = 300;
  counter;

  // full screen
  isfullscreen = false;

  countmessageTasksdata=0;


  logout() {
    console.log('logout the user');
    this._authService.logout();
  }

  openFullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
    this.isfullscreen = true;
  }

  closefullscreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isfullscreen = false;
  }

  // copyToClipboard
  copyStringToClipboard(str, type) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    if (type == 'accessToken') {
      el.value = 'Bearer ' + str;
    } else {
      el.value = str;
    }
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    // el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  // extendSession if user click on okay.
  extendSession() {
    this._authService.tokenConformationFromUser.next();
    this.tokenConfirmation = false;
    // stoping counter
    clearInterval(this.counter);

    clearTimeout(this.userNotConfired);
  }

  // counter
  counterStart() {
    this.counter = setInterval(res => {
      //  if timerStart will reaches  to 0
      // if (this.timerStart === 0) {
      //   return clearInterval(this.counter);
      // }
      this.timerStart--;
      console.log(this.timerStart);
    }, 1000
    );
  }


  // logout  if user not click on okay. means no refresh
  checkUserConfirmation() {
    this.counterStart();
    this.userNotConfired = setTimeout(res => {
      clearInterval(this.counter);
      this._authService.logout();
    }, 5 * 60 * 1000); // 5mins as milliseconds
  }

  onClickAll(){
    this.router.navigate(['/vpd/jobs/message-center'])
  }



  constructor(private _authService: AuthService,public _jobservice:JobService, private router: Router,) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('logged_in_user'));
    console.log('logged_in_user ', this.loggedInUser);
    this.accessToken = localStorage.getItem('access_token');
    this.refreshToken = localStorage.getItem('refreshtoken');
    this._authService.isTokenGoingToExpiry.subscribe(res => {
      this.tokenConfirmation = true;
      // if user not clicked on okay then logout the user  from application
      this.checkUserConfirmation();
    });

    this._jobservice.getAllSubscribeEvents();
    this._jobservice.totalMessageTasksSubject.subscribe((res:any) =>{
      this.countmessageTasksdata = res.length;
  
    })
    //this._jobservice.getMessageCenterList()

    //Adding the Interval for auto refresh

    setInterval(()=> { this._jobservice.getAllSubscribeEvents() }, 5*60*1000);
  }

}

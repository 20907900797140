import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[togglePassword]'
})
export class TogglePasswordDirective implements OnInit {
  private _shown = false;
  @Input() hasPermission: boolean;

  constructor(private el: ElementRef) {

  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = '<i class="fa fa-eye-slash"></i>';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = '<i class="fa fa-eye"></i>';
    }
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.setAttribute('class', 'password-toggle');
    span.innerHTML = `<i class="fa fa-eye"></i>`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

  ngOnInit() {
    if (this.hasPermission) {
      this.setup();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientService } from 'src/app/services/httpClient.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  feedbackForm: FormGroup;


  constructor(
    private _formBuilder: FormBuilder,
    private _loader: LoadingService,
    private _httpclientservice: HttpClientService,
    private _toaster: ToasterService,
    private _loggedInUserService: LoggedInUserService

  ) { }



  ngOnInit() {
    this.feedbackForm = this._formBuilder.group({
      UserFeedback: [''],
      rating: ['']
    });

  }
  onsubmitFeeedback() {
    console.log('log', this._loggedInUserService.getLoggedInUserEntities);
    // loader start
    // this._loader.loaderStart();
    var itfeedback = this.feedbackForm.value;
    console.log('user agent', navigator.userAgent);
    let obj = {
      Feedback: this.feedbackForm.value.UserFeedback,
      Application: 'Payroll',
      Rating: parseInt(this.feedbackForm.value.rating),
      LoginName: JSON.parse(localStorage.getItem('logged_in_user')).username,
      // CreatedAt: '1582149575',
      UserName: JSON.parse(localStorage.getItem('logged_in_user')).username,
      // this._loggedInUserService.getLoggedInUser(),
      UserRole: 'Checker',
      UserAgent: navigator.userAgent
    };
    this._httpclientservice
      .post('feedback', obj, 'apiDevBaseUrladmin')
      .subscribe(
        newITslab => {
          this._loader.loaderEnd();
          // loader End
          this._toaster.success('Feedback Added Successfully');
          this.feedbackForm.reset();
          console.log("object for fd", obj);
        },
        err => {
          // this._loader.loaderEnd();
          // this._toaster.error(err);
          console.log("object for fd", obj);
          this._toaster.error('error');
        }
      );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';




@Injectable()
export class LoginUserGuardService implements CanActivate {
  constructor() { }

  canActivate(): boolean {

    let isVerifiedUser = localStorage.getItem('access_token');
    console.log("guard called", isVerifiedUser);
    if (isVerifiedUser) {
      return true;
    } else {
      return false;
    }
  }
}

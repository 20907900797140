import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {


  loggedInUser;                    // logged in user
  loggedInUserEntities;            // all entities belongs to the user
  loggedInUserSelectedEntitie;     // current entitie seleted by the user
  loggedInUserSelectedEntitieType; // current entitie Type seleted by the user

  // setter-getter for loggedin user
  setLoggedInUser(user) {
    this.loggedInUser = user;
  }
  getLoggedInUser() {
    return this.loggedInUser;
  }

  // setter-getter for LoggedIn User Entities
  setLoggedInUserEntities(userEntities) {
    this.loggedInUserEntities = userEntities;
  }
  getLoggedInUserEntities() {
    return this.loggedInUserEntities;
  }

  // setter-getter for LoggedIn User selected Entitie
  setLoggedInUserSelectedEntitie(userSelectedEntitie) {
    this.loggedInUserSelectedEntitie = userSelectedEntitie;
  }
  getLoggedInUserSelectedEntitie() {
    return this.loggedInUserSelectedEntitie;
  }

  // setter-getter for LoggedIn User selected Entitie type
  setLoggedInUserSelectedEntitieType(userSelectedEntitieType) {
    this.loggedInUserSelectedEntitie = userSelectedEntitieType;
  }
  getLoggedInUserSelectedEntitieType() {
    return this.loggedInUserSelectedEntitieType;
  }

}

import { ToasterService } from './../services/toaster.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TokenInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('INTERCEPTOR');

    // We retrieve the token, if any
    const token = localStorage.getItem('access_token');
    let newHeaders = req.headers;
    // newHeaders = newHeaders.append('entity_type', 'S');
    // newHeaders = newHeaders.append('entity_key', 'SYS');
    newHeaders = newHeaders.append('entitytype', 'S');
    newHeaders = newHeaders.append('entitykey', 'SYS');
    newHeaders = newHeaders.append('orgid', '1');
    if (token) {
      // If we have a token, we append it to our new headers
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
    }
    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
    const authReq = req.clone({ headers: newHeaders });
    // Then we return an Observable that will run the request
    return next.handle(authReq).pipe(tap(() => { },
      (err: any) => {
        console.log('HttpErrorResponse Interceptor ', err);
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }

          this._router.navigate(['login']).then(res => {
            this._toasterService.error('Session expired. Please login again.');
          });
        }
      }));
  }
  constructor(private _router: Router, private _toasterService: ToasterService) {

  }
}

import { Component, OnInit } from '@angular/core';
import { IndependentSharedService } from '../../../services/independentShared.service'
// import {environment} from '../../../environments/environment'
@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.css']
})
export class ErrorHandlingComponent {
  errorsbyServer: any;
  debugInfo: any;
  showException;
  showInfo = false;

  constructor(indservice: IndependentSharedService) {
    indservice._error.asObservable().subscribe((res) => {
      console.log("errorr by sharedService in errror handling", res)
      this.errorsbyServer = res['errors'];
      this.debugInfo = res['debugInfo'];
      // this.showException = environment.showException;
      document.getElementById('erroropenmodel').click();
    });
  }

  page = 1;

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToCurrency'
})
export class NumberToCurrencyPipe implements PipeTransform {

  a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '];

  b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'];

  transform(number: any, args?: any): any {
    if (number) {
      // let num: any = Number(value);
      // if (num) {
      //   if ((num = num.toString()).length > 9) { return 'We are not the Iron Bank, you can lower down the stakes :)'; }
      //   const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      //   if (!n) { return ''; }
      //   let str = '';
      //   str += (Number(n[1]) !== 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'crore ' : '';
      //   str += (Number(n[2]) !== 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'lakh ' : '';
      //   str += (Number(n[3]) !== 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'thousand ' : '';
      //   str += (Number(n[4]) !== 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'hundred ' : '';
      //   str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') +
      //     (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' +
      //       this.a[n[5][1]]) + 'rupee' : '';
      //   return str;
      // } else {
      //   return '';
      // }

      var convertedValue = '';
      var originalNumber = Math.abs(Number(number));
      if (originalNumber > 0 && originalNumber <= 999) {
        if (originalNumber / 100 <= 1) {
          convertedValue = originalNumber / 100 + ' Hundred';
        } else {
          convertedValue = originalNumber / 100 + ' Hundreds';
        }
      } else if (originalNumber > 999 && originalNumber <= 99999) {
        if (originalNumber / 1000 <= 1) {
          convertedValue = originalNumber / 1000 + ' Thousand';
        } else {
          convertedValue = originalNumber / 1000 + ' Thousand';
        }
      } else if (originalNumber > 99999 && originalNumber <= 9999999) {
        if (originalNumber / 100000 <= 1) {
          convertedValue = originalNumber / 100000 + ' Lakh';
        } else {
          convertedValue = originalNumber / 100000 + ' Lakhs';
        }
      } else if (originalNumber > 9999999) {
        if (originalNumber / 10000000 <= 1) {
          convertedValue = originalNumber / 10000000 + ' Crore';
        } else {
          convertedValue = originalNumber / 10000000 + ' Crores';
        }
      } else {
        convertedValue = originalNumber + '';
      }
      if (number < 0) {
        convertedValue = '-' + convertedValue;
      }
      let convertedValueToRound: any = convertedValue.split(' ');
      // checking if the number is interger or float value
      if (parseFloat(convertedValueToRound[0]) % 1 === 0) {
        // number is integer
        return convertedValueToRound[0] + ' ' + convertedValueToRound[1];
      } else {
        // number is float so rouning up the value
        return parseFloat(convertedValueToRound[0]).toFixed(1) + ' ' + convertedValueToRound[1];
      }

    } else {
      return '';
    }
  }
}

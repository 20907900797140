import { LoginUserGuardService } from './guards/isUserLogedInOrNot';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'vpd',
    loadChildren: './vpdConsole/vpd.module#VpdModule',
    canActivate: [LoginUserGuardService]
  },
  // fallback routes redirect to loign
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
      scrollPositionRestoration: 'enabled'  // scroll to top when route changed
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
